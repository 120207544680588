import {Component} from "react";
import {BOOK_NEW_LOCKER, OPEN_LOCKER} from "../assets/constants/Constants";
import ShowToast from "../components/ToastComponent";
import {withTranslation} from "react-i18next";

class LockerOpenedPage extends Component {
    constructor (props) {
        super (props)

        this.state = {
            isLoading: false
        }
    }

    componentDidMount = () => {
        console.log('Page mounted - Locker Opened page');
    }

    componentWillUnmount = () => {
        console.log('Page unmounted - Locke Opened page');
    }

    render () {
        return (
            <div className="locker-opened-page">
                <div className="header-container">
                    <div className="header">
                        <div className="page-heading">Locker Opened</div>
                        <img className="durolt-logo" src={require("../assets/images/durolt_app_logo.png")} alt={this.props.t('durolt_logo')}/>
                    </div>
                </div>
                <div className="main-container">
                    {this.state.isLoading ? (
                        <div className="main">Loading...</div>
                    ) : (
                        <div className="main">
                            <div className="locker-opened-info">Locker 101 is opened.</div>
                            <div className="locker-usage-instructions">Close the locker properly after use.</div>
                            <div className="release-locker-button">Release</div>
                            <div className="book-another-locker-button">Book Another Locker</div>
                        </div>
                    )}
                </div>
                <div className="footer-container">
                    <div className="footer"></div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(LockerOpenedPage);