import {Component} from "react";
import i18n from "i18next";
import {withTranslation} from "react-i18next";
import Select from "react-select";
import {OPEN_LOCKER, BOOK_NEW_LOCKER, SUCCESS, WARNING, GUEST_AUTH_TOKEN, GUEST_AUTH_TOKEN_VALUE, TOAST_WARN} from "../assets/constants/Constants";
import {ROTATING_LOADING_ICON} from "../assets/constants/Icons";
import {getTenantAndAssignmentSettings} from "../lib/BackendUtils";
import ShowToast from "../components/ToastComponent";

class HomePage extends Component {
    constructor (props) {
        super (props)

        sessionStorage.clear();

        let lockerBankId = null;

        if (this.props.history.location.state?.isQRCodeUsedToLoadTheWebsite) {
            lockerBankId = this.props.history.location.state.lockerBankId;
            localStorage.setItem("lockerBankId", lockerBankId);
        }
        else if (localStorage.getItem("lockerBankId")) {
            lockerBankId = localStorage.getItem("lockerBankId");
        }

        this.state = {
            isLoading: true,
            message: (this.props.history.location.state?.message) ? this.props.history.location.state.message : null,
            selectedLanguage: {value: 'en', label: 'English'},
            lockerBankId: lockerBankId,
            areTermsAndConditionsAccepted: false,
            isLockerBankActive: false,
            isLockerBankConfiguredForLuggageUseCase: false,
            isOTPLoginEnabled: false,
            isOTPlessLoginEnabled: false,
            isAutomaticLockerUnitAssignmentEnabled: false,
            isManualLockerUnitSelectionEnabled: false,
            isLockerBankActiveAndConfiguredForLuggageUseCase: false,
            isAnyLoginMethodEnabled: false,
            isAutomaticLockerUnitAssignmentOrManualLockerUnitSelectionEnabled: false
        }
    }

    componentDidMount = async () => {
        if (this.state.message) {
            ShowToast(this.state.message, TOAST_WARN);
        }

        if (this.state.lockerBankId) {
            await this.getTenantAndAssignmentSettingsAndCheckIfLockerBankIsConfiguredForLuggageUseCase();
        }

        this.setState({
            isLoading: false
        });
    }

    getTenantAndAssignmentSettingsAndCheckIfLockerBankIsConfiguredForLuggageUseCase = async () => {
        const additionalHeaders = {[GUEST_AUTH_TOKEN]: GUEST_AUTH_TOKEN_VALUE};
        const body = {locker_bank: this.state.lockerBankId};
        const response = await getTenantAndAssignmentSettings(additionalHeaders, body);

        switch (response.status) {
            case SUCCESS:
                let isLockerBankActive = false;
                let isLockerBankConfiguredForLuggageUseCase = false;
                let isOTPLoginEnabled = false;
                let isOTPlessLoginEnabled = false;
                let isAutomaticLockerUnitAssignmentEnabled = false;
                let isManualLockerUnitSelectionEnabled = false;

                if (response.data.assignments) {
                    isLockerBankActive = response.data.assignments[0].is_active;

                    response.data.assignments[0].settings.forEach((individualSetting) => {
                        if (individualSetting.key === "luggage") {
                            isLockerBankConfiguredForLuggageUseCase = individualSetting.value;
                        }
                        else if (individualSetting.key === "verify_with_otp") {
                            isOTPLoginEnabled = individualSetting.value;
                        }
                        else if (individualSetting.key === "otpless") {
                            isOTPlessLoginEnabled = individualSetting.value;
                        }
                        else if (individualSetting.key === "any_available") {
                            isAutomaticLockerUnitAssignmentEnabled = individualSetting.value;
                        }
                        else if (individualSetting.key === "select_manual") {
                            isManualLockerUnitSelectionEnabled = individualSetting.value;
                        }
                    });
                }

                this.setState({
                    isLockerBankActive: isLockerBankActive,
                    isLockerBankConfiguredForLuggageUseCase: isLockerBankConfiguredForLuggageUseCase,
                    isOTPLoginEnabled: isOTPLoginEnabled,
                    isOTPlessLoginEnabled: isOTPlessLoginEnabled,
                    isAutomaticLockerUnitAssignmentEnabled: isAutomaticLockerUnitAssignmentEnabled,
                    isManualLockerUnitSelectionEnabled: isManualLockerUnitSelectionEnabled,
                    isLockerBankActiveAndConfiguredForLuggageUseCase: (isLockerBankActive && isLockerBankConfiguredForLuggageUseCase),
                    isAnyLoginMethodEnabled: (isOTPLoginEnabled || isOTPlessLoginEnabled),
                    isAutomaticLockerUnitAssignmentOrManualLockerUnitSelectionEnabled: (isAutomaticLockerUnitAssignmentEnabled || isManualLockerUnitSelectionEnabled)
                }, () => {
                    localStorage.setItem("isOTPLoginEnabled", isOTPLoginEnabled);
                    localStorage.setItem("isOTPlessLoginEnabled", isOTPlessLoginEnabled);
                });
                break;

            case WARNING:
                this.setState({
                    isLockerBankActiveAndConfiguredForLuggageUseCase: false
                }, () => {
                    ShowToast(response.message, TOAST_WARN);
                });
                break;

            default:
                this.setState({
                    isLockerBankActiveAndConfiguredForLuggageUseCase: false
                }, () => {
                    ShowToast(`warning message`, TOAST_WARN);
                });
        }
    }

    loginHandler = (userAction) => {
        localStorage.setItem("userAction", userAction);

        this.props.history.push({
            pathname: "/login",
            state: {
                isOTPLoginEnabled: this.state.isOTPLoginEnabled,
                isOTPlessLoginEnabled: this.state.isOTPlessLoginEnabled,
            }
        });
    }

    tryAgainButtonHandler = () => {
        this.setState({
            isLoading: true
        }, async () => {
            await this.getTenantAndAssignmentSettingsAndCheckIfLockerBankIsConfiguredForLuggageUseCase();
        });

        this.setState({
            isLoading: false
        });
    }

    componentWillUnmount = () => {}

    render () {
        const {t} = this.props;

        return (
            <div className="home-page">
                <div className="header-container">
                    <div className="header">
                        <img className="durolt-logo" src={require("../assets/images/durolt_app_logo.png")} alt={t('durolt_logo')}/>
                    </div>
                </div>
                <div className="main-container">
                    {this.state.isLoading && (
                        <div className="main">
                            <div className="loading-icon-container">{ROTATING_LOADING_ICON()}</div>
                        </div>
                    )}
                    {(!this.state.isLoading && this.state.lockerBankId === null) && (
                        <div className="main">
                            <div className="locker-bank-under-maintenance">
                                <div className="error-message">We couldn't recognise the locker bank you are trying to access. To book a new locker or to open your locker, please scan the QR code on the locker bank.</div>
                            </div>
                        </div>
                    )}
                    {(!this.state.isLoading && this.state.lockerBankId !== null && this.state.isLockerBankActiveAndConfiguredForLuggageUseCase) && (
                        <div className="main">
                            <form className="terms-and-conditions-form">
                                <input type="checkbox" id="termsAndConditions" name="termsAndConditions" checked={this.state.areTermsAndConditionsAccpted} onChange={() => this.setState({areTermsAndConditionsAccepted: !this.state.areTermsAndConditionsAccepted}, () => console.log('TnC accepted? ', this.state.areTermsAndConditionsAccepted))} />
                                <label htmlFor="termsAndConditions">I accept <span className="terms-and-conditions-page-link" onClick={() => { this.props.history.push({pathname: "/terms-and-conditions"}) }}>terms and conditions.</span></label>
                            </form>
                            {this.state.areTermsAndConditionsAccepted ? (
                                <div className="buttons-container">
                                    <div className="book-new-locker-button" onClick={() => this.loginHandler(BOOK_NEW_LOCKER) }>{t('book_new_locker')}</div>
                                    <div className="open-locker-button" onClick={() => this.loginHandler(OPEN_LOCKER) }>{t('open_locker')}</div>
                                </div>
                            ) : (
                                <div className="buttons-container">
                                    <div className="book-new-locker-button disabled-button" onClick={() => ShowToast(`${t('warning_accept_tnc_to_book_new_locker')}`, TOAST_WARN)}>{t('book_new_locker')}</div>
                                    <div className="open-locker-button disabled-button" onClick={() => ShowToast(`${t('warning_accept_tnc_to_open_locker')}`, TOAST_WARN)}>{t('open_locker')}</div>
                                </div>
                            )}
                        </div>
                    )}
                    {(!this.state.isLoading && this.state.lockerBankId !== null && !this.state.isLockerBankActiveAndConfiguredForLuggageUseCase) && (
                        <div className="main">
                            <div className="locker-bank-under-maintenance">
                                <div className="error-message">The locker bank is currently under maintenance. Please try again later.</div>
                                <div className="try-again-button" onClick={() => this.tryAgainButtonHandler() }>Try again</div>
                            </div>
                        </div>
                    )}
                </div>
                <div className="footer-container">
                    <div className="footer">
                        <div className="language-selector-dropdown-container">
                            <div className="title language-selector-dropdown-title">Select language</div>
                            <div className="language-selector-dropdown">
                                <Select
                                    className="react-select language-selector"
                                    classNamePrefix="language-selector"
                                    isSearchable={true}
                                    menuPlacement="top"
                                    isRtl={false}
                                    value={this.state.selectedLanguage}
                                    onChange={(event) => {
                                        this.setState({
                                            selectedLanguage: event
                                        }, () => {
                                            i18n.changeLanguage(event.value);
                                        });
                                    }}
                                    options={[
                                        {value: 'en', label: 'English'},
                                        {value: 'hi', label: 'Hindi'},
                                        {value: 'mr', label: 'Marathi'},
                                        {value: 'kn', label: 'Kannada'}
                                    ]}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(HomePage);