import {Component} from "react";

class LandingPage extends Component {
    constructor (props) {
        super (props)

        const lockerBankId = this.props.match.params.lockerBankId;
        // console.log('lockerBankId: ', lockerBankId, typeof lockerBankId);
        this.props.history.push({
            pathname: "/",
            state: {
                lockerBankId: lockerBankId ? parseInt(lockerBankId) : null,
                isQRCodeUsedToLoadTheWebsite: true,
                message: lockerBankId ? null : `The QR code scanned is not valid.`
            }
        });
    }

    render () {
        return (
            <></>
        );
    }
}

export default LandingPage;