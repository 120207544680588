export const DOMAIN = "https://smartclient.durolt.in";
export const API = "api";
export const MOBILE = "mobile";
export const LUGGAGE = "luggage";
export const LUGGAGE_SETTINGS = "luggage-settings";
export const SEND_OTP = "send-otp";
export const VERIFY_OTP = "verify-otp";
export const AVAILABLE_SIZES = "available-sizes";
export const ASSIGN_SIZES = "assign-sizes";
export const PRE_PAYMENT_REGISTER = "pre-payment-register";
export const SAVE_TRANSACTION = "save-transaction";
export const LOCKERS = "lockers";
export const ASSIGNED = "assigned";
export const GET = "GET";
export const POST = "POST";
export const SUCCESS = "success";
export const ERROR = "error";
export const WARNING = "warning";
export const REDIRECT = "redirect";
export const GUEST_AUTH_TOKEN = 'GUEST-AUTH-TOKEN';
export const GUEST_AUTH_TOKEN_VALUE = "#up!7O@rh#55Fp3I1CpHQ!d5xs2J2c#FRc@x^bb@ZHTzK5@v5bCivAjSjAx^3Vhs";

// OTPless authentication
export const OTPLESS_AUTHENTICATION_SOURCE = "https://otpless.com/auth.js";
export const OTPLESS_DUROLT_CID = "VCA1GURCQ7JRRAGY70FRFX9ZBXUJHDFV";

//Toast Message Types
export const TOAST_INFO = "INFO";
export const TOAST_WARN = "WARN";
export const TOAST_SUCCESS = "SUCCESS";
export const TOAST_ERROR = "ERROR";

// Regular expressions
export const REGEXP_EMAIL = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/i;
export const REGEXP_MOBILE = /[0-9\+\s]/i;
export const REGEXP_OTP = /[0-9]{4}/i;

// User actions
export const OPEN_LOCKER = "openLocker";
export const BOOK_NEW_LOCKER = "bookNewLocker";