import {Component} from "react";
import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import HTTPApi from "i18next-http-backend";
import {BrowserRouter} from "react-router-dom";
import RouterComponent from "./components/RouterComponent";

i18n.use(initReactI18next).use(HTTPApi).init({
  lng: "en",
  fallbackLng: "en",
  backend: {
    loadPath: "/assets/locales/{{lng}}/translation.json"
  },
  interpolation: {
    escapeValue: false
  }
});

class App extends Component {
  constructor (props) {
    super (props)
  }

  render () { 
    return (
      <BrowserRouter>
        <RouterComponent/>
      </BrowserRouter>
    );
  }
}

export default App;
