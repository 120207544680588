import {Component} from "react";
import SlideButton from "react-slide-button";
import {SUCCESS, WARNING, TOAST_WARN} from "../assets/constants/Constants";
import {APP_GREEN_COLOR} from "../assets/constants/Colors";
import {ROTATING_LOADING_ICON, CHEVRONS_RIGHT} from "../assets/constants/Icons";
import {getAssignedLockers} from "../lib/BackendUtils";
import ShowToast from "../components/ToastComponent";
import {withTranslation} from "react-i18next";

class MyLockersPage extends Component {
    constructor (props) {
        super (props)

        this.state = {
            isLoading: true,
            isOpeningLockerUnit: false,
            bookedLockersList: [],
            lockerUnitToOpen: null
        }
    }

    componentDidMount = () => {
        console.log('Page mounted - my lockers page');
        this.getLockersAssignedToUser();
    }

    getLockersAssignedToUser = async () => {
        const additionalHeaders = {Authorization: `Token ${sessionStorage.getItem("authorizationToken")}`};
        const response = await getAssignedLockers(additionalHeaders);
        console.log('response: ', response);
        switch (response.status) {
            case SUCCESS:
                this.setState({
                    isLoading: false,
                    bookedLockersList: response.data.assigned_lockers.length > 0 ? response.data.assigned_lockers : []
                }, () => {
                    console.log('bookedLockersList: ', this.state.bookedLockersList);
                });
                break;
            case WARNING:
                this.setState({
                    isLoading: false
                }, () => {
                    ShowToast(response.message, TOAST_WARN)
                });
                break;
        }
    }

    componentWillUnmount = () => {
        console.log('Page unmounted - my lockers page');
    }

    render () {
        return (
            <div className="my-lockers-page">
                <div className="header-container">
                    <div className="header">
                        <div className="page-heading">My Lockers</div>
                        <img className="durolt-logo" src={require("../assets/images/durolt_app_logo.png")} alt={this.props.t("durolt_logo")}/>
                    </div>
                </div>
                <div className="main-container">
                    {this.state.isLoading && (
                        <div className="main">
                            <div className="loading-icon-container">{ROTATING_LOADING_ICON()}</div>
                        </div>
                    )}
                    {(!this.state.isLoading && this.state.isOpeningLockerUnit) && (
                        <div className="main">
                            <div className="loading-icon-container">{ROTATING_LOADING_ICON()}</div>
                            <div className="loading-text">Opening locker {this.state.lockerUnitToOpen}. Please wait.</div>
                        </div>
                    )}
                    {(!this.state.isLoading && !this.state.isOpeningLockerUnit) && (
                        <div className="main">
                            {this.state.bookedLockersList.length > 0 ? (
                                <div className="booked-lockers-list">
                                    {this.state.bookedLockersList.map((locker, index) => {
                                        if (this.state.lockerUnitToOpen === locker.locker_unit) {
                                            return (
                                                <SlideButton
                                                    mainText={locker.locker_unit}
                                                    overlayText={`Open locker ${locker.locker_unit}`}
                                                    caret={CHEVRONS_RIGHT()}
                                                    classList="locker-unit slide-button"
                                                    overlayClassList="slide-button-overlay"
                                                    caretClassList="slide-button-caret"
                                                    minSlideWidth={0.8}
                                                    minSlideVelocity={1}
                                                    onSlideDone={() => this.props.history.push({pathname:"/locker-opened"})}
                                                />
                                            );
                                        }
                                        else {
                                            return (
                                                <div className="locker-unit" onClick={() => this.setState({lockerUnitToOpen: locker.locker_unit})}>{locker.locker_unit}</div>
                                            );
                                        }
                                    })}
                                </div>
                            ) : (
                                <div className="booked-lockers-list">
                                    <div className="warning-message">You haven't booked any lockers.</div>
                                </div>
                            )}
                            <div className="book-another-locker-button" onClick={() => this.props.history.push({pathname: "/locker-size"}) }>{this.state.bookedLockersList.length > 0 ? `Book Another Locker` : `Book New Locker`}</div>
                        </div>
                    )}
                </div>
                <div className="footer-container">
                    <div className="footer"></div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(MyLockersPage);