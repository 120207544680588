import {Component} from "react";
import {BOOK_NEW_LOCKER, OPEN_LOCKER} from "../assets/constants/Constants";
import ShowToast from "../components/ToastComponent";
import {withTranslation} from "react-i18next";
import { generateHash, getPaymentServerURL, getPaymentURL, invokePaymentGateway } from "../lib/PaymentUtils";

class PaymentAcceptPage extends Component {
    constructor (props) {
        super (props)

        this.state = {
            isLoading: false
        }
    }

    componentDidMount = () => {
        console.log('Page Mounted - Payment Details Page');
    }

    componentWillUnmount = () => {
        console.log('Page Unmounted - Payment Detials Page');
    }

    initiatePayment = async (headers, body) => {

        this.props.history.push({
            pathname: "/payment-gateway"
        });

        /*
        let initiatePaymentFormData = new FormData();

        const paymentParams = {
            'key': '2PBP7IABZ2',
            'txnid': '342452345',
            'amount': '2.20',
            'email': 'support@durolt.com',
            'phone': '9832423784',
            'firstname': 'Locker User',
            'udf1': '',
            'udf2': '',
            'udf3': '',
            'udf4': '',
            'udf5': '',
            'hash': '',
            'productinfo': 'Looker Booking',
            'udf6': '',
            'udf7': '',
            'udf8': '',
            'udf9': '',
            'udf10': '',
            'surl': 'https://pwadev.durolt.in/payment-completion',
            'furl': 'https://pwadev.durolt.in/payment-completion',
        };

        const payloadHashKey = generateHash(paymentParams);
        paymentParams.hash = payloadHashKey;
        for ( let key in paymentParams ) {
            initiatePaymentFormData.append(key, paymentParams[key]);
        }
        // console.log("Form Data:", initiatePaymentFormData);
        // console.log("Form Data:");
        // for (let pair of initiatePaymentFormData.entries()) {
        //     console.log(pair[0]+ '=' + pair[1]); 
        // }
        const paymentURL = getPaymentServerURL("test") + 'payment/initiateLink';
        // console.log("Payment URL:"+paymentURL);
        // var util = require('../lib/Easebuzz/util.js');

        // util.call(paymentURL, paymentParams).then(function (response) {
        //     //pay(response.data, payment_url)
        //     console.log("Response:", response.data);
        //   });

        // invokePaymentGateway(paymentURL, initiatePaymentFormData).then((response) => {
        //     console.log("Response:", response.data);
        //     // pay(response.data, payment_url)
        //   });
        
        invokePaymentGateway();

        // const response = await fetch(url, options)
        // .then((resp) => {
        //     return resp.json();
        // }).then((respJSON) => {
        //     return respJSON;
        // }).catch((error) => {
        //     console.log('Error: ', JSON.stringify(error));
        // });

        // let response = await fetch (URL_verifyOTP, {
        //     method: 'POST',
        //     headers: headers,
        //     body: JSON.stringify(body)
        // }).then((resp) => {
        //     return resp.json();
        // }).then((respJSON) => {
        //     return respJSON;
        // }).catch((error) => {
        //     console.log('Error: ', JSON.stringify(error));
        // });
    
        // return response;
        */
    }

    render () {
        return (
            <div className="payment-details-page">
                <div className="header-container">
                    <div className="header">Payment</div>
                </div>
                <div className="main-container">
                    {this.state.isLoading ? (
                        <div className="main">Loading...</div>
                    ) : (
                        <div className="main">
                            <div className="booking-info">
                                <div className="info-row">
                                    <div>Locker Number:</div>
                                    <div>101</div>
                                </div>
                                <div className="info-row">
                                    <div>Locker Size:</div>
                                    <div>Small</div>
                                </div>
                                <div className="info-row">
                                    <div className="booking-period-title">Booking Period:</div>
                                    <div className="booking-period-value">
                                        <div>2 hrs</div>
                                        <div>(9:30 AM - 11:30 AM)</div>
                                    </div>
                                </div>
                                <div className="info-row">
                                    <div>Total Amount:</div>
                                    <div>200 Rs.</div>
                                </div>
                            </div>
                            <div className="overstay-info">Overstay information</div>
                            <div className="pay-button" onClick={() => this.initiatePayment()}>Start Payment</div>
                        </div>
                    )}
                </div>
                <div className="footer-container">
                    <div className="footer"></div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(PaymentAcceptPage);