import {Component} from "react";
import moment from "moment";
import {SUCCESS, WARNING, TOAST_WARN} from "../assets/constants/Constants";
import {ROTATING_LOADING_ICON} from "../assets/constants/Icons";
import {APP_WHITE_COLOR} from "../assets/constants/Colors";
import {getLockerSizeAvailability} from "../lib/BackendUtils";
import ShowToast from "../components/ToastComponent";
import {withTranslation} from "react-i18next";

class LockerSizePage extends Component {
    constructor (props) {
        super (props)

        if (!localStorage.getItem("lockerBankId")) {
            this.props.history.push({
                pathname: "/"
            });
        }
        
        this.state = {
            isLoading: true,
            checkingIfSelectedLockerSizeIsStillAvailable: false,
            showBookingDurationTimestampsIfTrueAndWarningIfFalse: true,
            updateBookingStartAndEndTimesAfterEveryMinute: false,
            lockerBankId: localStorage.getItem("lockerBankId"),
            referenceId: sessionStorage.getItem("referenceId"),
            token: sessionStorage.getItem("authorizationToken"),
            availableLockerSizes: [],
            minimumBookingDurationInHours: 1,
            selectedLockerSize: {size: null},
            selectedBookingDurationInHours: 1,
            bookingDurationStartTimestamp: null,
            bookingDurationEndTimestamp: null,
            totalAmount: 0
        }
    }

    componentDidMount = async () => {
        const availableLockerSizes = await this.getAvailableLockerSizes();
        this.setState({
            isLoading: false,
            availableLockerSizes: availableLockerSizes
        });
    }

    getAvailableLockerSizes = async () => {
        let availableLockerSizes = [];
        let additionalHeaders = {'Authorization': `token ${this.state.token}`};
        let body = {ref_id: this.state.referenceId};
        let response = await getLockerSizeAvailability(additionalHeaders, body, this.state.lockerBankId);

        switch (response.status) {
            case SUCCESS:
                if (response.data.locker_sizes) {
                    availableLockerSizes = response.data.locker_sizes.filter((item) => (item.size !== null && item.size !== '' && item.name !== null && item.name !== ''));
                }
                break;
            case WARNING:
                ShowToast(response.message, TOAST_WARN);
                break;
        }

        return availableLockerSizes;
    }

    lockerSizeSelectionHandler = (selectedLockerSize) => {
        // console.log('selected locker size: ', selectedLockerSize);
        const currentDateTimeInMilliSeconds = moment().valueOf();

        this.setState({
            selectedLockerSize: selectedLockerSize,
            selectedBookingDurationInHours: this.state.minimumBookingDurationInHours,
            showBookingDurationTimestampsIfTrueAndWarningIfFalse: true,
            bookingDurationStartTimestamp: currentDateTimeInMilliSeconds,
            bookingDurationEndTimestamp: currentDateTimeInMilliSeconds + (this.state.minimumBookingDurationInHours * 3600000),
            totalAmount: parseFloat(selectedLockerSize.price) * this.state.minimumBookingDurationInHours,
            updateBookingStartAndEndTimesAfterEveryMinute: true
        }, () => this.bookingStartAndEndTimesUpdaterAfterEveryMinute() );
    }

    bookingStartAndEndTimesUpdaterAfterEveryMinute = () => {
        const bookingStartAndEndTimesUpdaterInterval = setInterval(() => {
            if (this.state.updateBookingStartAndEndTimesAfterEveryMinute) {
                if (moment().seconds() === 0) {
                    this.setState({
                        bookingDurationStartTimestamp: this.state.bookingDurationStartTimestamp + 60000,
                        bookingDurationEndTimestamp: this.state.bookingDurationEndTimestamp + 60000
                    });
                }
            }
            else {
                clearInterval(bookingStartAndEndTimesUpdaterInterval);
            }
        }, 1000);
    }

    bookingDurationInputHandler = (selectedLockerSize, selectedBookingDurationInHours) => {
        if (selectedBookingDurationInHours.length === 0 || /^[1-9]\d*$/.test(selectedBookingDurationInHours)) {
            const currentDateTimeInMilliSeconds = moment().valueOf();

            this.setState({
                selectedBookingDurationInHours: selectedBookingDurationInHours,
                showBookingDurationTimestampsIfTrueAndWarningIfFalse: selectedBookingDurationInHours.length > 0 ? true : false,
                bookingDurationStartTimestamp: currentDateTimeInMilliSeconds,
                bookingDurationEndTimestamp: (selectedBookingDurationInHours.length > 0) ? (currentDateTimeInMilliSeconds + (parseFloat(selectedBookingDurationInHours) * 3600000)) : currentDateTimeInMilliSeconds,
                totalAmount: (selectedBookingDurationInHours.length > 0) ? (parseFloat(selectedLockerSize.price) * parseFloat(selectedBookingDurationInHours)) : 0
            });
        }
        else {
            ShowToast(`Booking duration cannot be less than ${this.state.minimumBookingDurationInHours} hour.`, TOAST_WARN);
        }
    }

    checkIfSelectedLockerSizeIsStillAvailableAndProceedToPaymentDetails = async () => {
        if (this.state.selectedLockerSize.size !== null && (this.state.selectedBookingDurationInHours >= this.state.minimumBookingDurationInHours)) {
            this.setState({
                checkingIfSelectedLockerSizeIsStillAvailable: true,
                updateBookingStartAndEndTimesAfterEveryMinute: false
            });

            const availableLockerSizes = await this.getAvailableLockerSizes();
            const selectedLockerSizeDetails = availableLockerSizes.find((availableLockerSize) => availableLockerSize.size === this.state.selectedLockerSize.size );

            if (selectedLockerSizeDetails) {
                // localStorage.setItem("blockedLockerUnitTillUserMakesSuccessfulPayment", selectedLockerSizeDetails.available_lockers_list.shift());

                this.setState({
                    checkingIfSelectedLockerSizeIsStillAvailable: false
                }, () => {
                    this.props.history.push({
                        pathname: "/payment-details",
                        state: {
                            selectedLockerSize: this.state.selectedLockerSize,
                            selectedBookingDurationInHours: this.state.selectedBookingDurationInHours,
                            bookingDurationStartTimestamp: this.state.bookingDurationStartTimestamp,
                            bookingDurationEndTimestamp: this.state.bookingDurationEndTimestamp,
                            totalAmount: this.state.totalAmount
                        }
                    });
                });
            }
            else {
                this.setState({
                    checkingIfSelectedLockerSizeIsStillAvailable: false,
                    availableLockerSizes: availableLockerSizes,
                    selectedBookingDurationInHours: this.state.minimumBookingDurationInHours,
                    totalAmount: this.state.totalAmount
                });
                ShowToast(`${this.state.selectedLockerSize} size is no longer available to book. Please try after sometime or select a different size.`, TOAST_WARN);
                this.setState({
                    selectedLockerSize: {size: null},
                });
            }
        }
        else if (this.state.selectedBookingDurationInHours < this.state.minimumBookingDurationInHours) {
            ShowToast('Minimum booking duration is 1 hour.', TOAST_WARN);
        }
        else {
            ShowToast('Please select a locker size', TOAST_WARN);
        }
    }

    componentWillUnmount = () => {}

    render () {
        const {t} = this.props;

        return (
            <div className="locker-size-page">
                <div className="header-container">
                    <div className="header">
                        <div className="page-heading">Locker Size</div>
                        <img className="durolt-logo" src={require("../assets/images/durolt_app_logo.png")} alt={t('durolt_logo')}/>
                    </div>
                </div>
                {/* {this.state.isLoading ? ( */}
                    {/* <div className="loading">{ROTATING_LOADING_ICON()}</div> */}
                {/* ) : ( */}
                    <div className="main-container">
                        {this.state.isLoading && (
                            <div className="main">
                                <div className="loading-icon-container">{ROTATING_LOADING_ICON()}</div>
                            </div>
                        )}
                        {(!this.state.isLoading && this.state.availableLockerSizes.length > 0) && (
                            <div className="main">
                                <div className="locker-size-form-heading">Select Locker Size</div>
                                <form className="locker-size-form">
                                    {this.state.availableLockerSizes.map( (item, index) => (
                                        <div className="form-item" key={index+1}>
                                            <label className="form-label" htmlFor={item.name.toLowerCase()}>
                                                <input className="form-input" type="radio" id={item.name.toLowerCase()} name="availableLockerSize" value={item.size} onChange={(event) => this.lockerSizeSelectionHandler(item) } />
                                                {item.name} ({item.dimension})
                                            </label>
                                            {(this.state.selectedLockerSize.size === item.size) && (
                                                <div className="booking-period-form">
                                                    <div className="locker-price">
                                                        <div className="title">Price:</div>
                                                        <div className="value">{item.price} Rs.</div>
                                                    </div>
                                                    <div className="booking-period">
                                                        <div className="title">Duration:</div>
                                                        <input className="form-input value" type="number" id="bookingDuration" name="bookingDuration" value={this.state.selectedBookingDurationInHours} placeholder="Duration" onChange={(event) => this.bookingDurationInputHandler(item, event.target.value) } />
                                                    </div>
                                                    <div className="booking-start-and-end-times">
                                                        {this.state.showBookingDurationTimestampsIfTrueAndWarningIfFalse ? (
                                                            <div className="value">{`(${moment(this.state.bookingDurationStartTimestamp).format(`DD MMM, HH:mm`)} - ${moment(this.state.bookingDurationEndTimestamp).format(`DD MMM, HH:mm`)})`}</div>
                                                        ) : (
                                                            <div className="booking-duration-warning">Minimum booking duration is 1 hour.</div>
                                                        )}
                                                    </div>
                                                    <div className="total-amount">
                                                        <div className="title">Total amount:</div>
                                                        <div className="value">{this.state.totalAmount} Rs.</div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                </form>
                                <div className={this.state.selectedLockerSize.size !== null ? `next-button` : `next-button disabled-button`} onClick={() => this.checkIfSelectedLockerSizeIsStillAvailableAndProceedToPaymentDetails()}>{this.state.checkingIfSelectedLockerSizeIsStillAvailable ? ROTATING_LOADING_ICON(APP_WHITE_COLOR) : `Next`}</div>
                            </div>
                        )}
                        {(!this.state.isLoading && this.state.availableLockerSizes.length === 0) && (
                            <div className="main">
                                <div className="">Locker not available</div>
                            </div>
                        )}
                    </div>
                {/* )} */}
                <div className="footer-container">
                    <div className="footer"></div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(LockerSizePage);