import {Component} from "react";
import {withTranslation} from "react-i18next";

class PaymentGatewayiFrame extends Component {
    constructor (props) {
        super (props)

        this.state = {
            isLoading: true,
            paymentURL : "https://testpay.easebuzz.in/",
            paymentModule : "v2/pay/",
            accessToken : "6947a3ccdcde308633fda7eb6f75b993ef94245b801ae1e00894b3b5e2a8ae67"
        }
    }

    componentDidMount = () => {
        console.log('Page mounted - payment gateway page');
    }

    render () {
        return (
        <div className="payment-gateway-page">
            <div className="header-container">
                <div className="header">
                    <div className="page-heading">Payment Gateway</div>
                    <img className="durolt-logo" src={require("../assets/images/durolt_app_logo.png")} alt={this.props.t("durolt_logo")}/>
                </div>
            </div>
            <div className="main-container">
                <div className="main">
                    <iframe
                        className="paymentiframe"
                        title="Payment Gateway"
                        src={this.state.paymentURL+this.state.paymentModule+this.state.accessToken}
                    />
                </div>
            </div>
            <div className="footer-container">
                <div className="footer"></div>
            </div>
        </div>
        );
    }
}

export default withTranslation()(PaymentGatewayiFrame);