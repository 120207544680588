import {Component} from "react";
import moment from "moment";
import {BOOK_NEW_LOCKER, OPEN_LOCKER} from "../assets/constants/Constants";
import {APP_WHITE_COLOR} from "../assets/constants/Colors";
import {ROTATING_LOADING_ICON} from "../assets/constants/Icons";
import ShowToast from "../components/ToastComponent";
import {withTranslation} from "react-i18next";

class PaymentDetailsPage extends Component {
    constructor (props) {
        super (props)

        this.state = {
            isLoading: true,
            selectedLockerSize: this.props.history.location.state.selectedLockerSize,
            selectedBookingDurationInHours: this.props.history.location.state.selectedBookingDurationInHours,
            bookingDurationStartTimestamp: this.props.history.location.state.bookingDurationStartTimestamp,
            bookingDurationEndTimestamp: this.props.history.location.state.bookingDurationEndTimestamp,
            totalAmount: this.props.history.location.state.totalAmount,
            remainingTimeToMakeSuccessfulPayment: null
        }
    }

    componentDidMount = () => {
        console.log('Make the prepayment api call and upon successful response, start the countdown timer');
        this.setState({
            isLoading: false
        }, () => this.countDownTimer() );
    }

    countDownTimer = () => {
        console.log('countdown timer started')
    }

    backButtonHandler = () => {
        this.props.history.push({
            pathname: "/locker-size"
        });
    }
    
    componentWillUnmount = () => {
        console.log('Page Unmounted - Payment Detials Page');
    }

    render () {
        return (
            <div className="payment-details-page">
                <div className="header-container">
                    <div className="header">
                        <div className="back-button" onClick={() => this.backButtonHandler() }>{`< Back`}</div>
                        <div className="page-heading">Payment</div>
                        <img className="durolt-logo" src={require("../assets/images/durolt_app_logo.png")} alt={this.props.t('durolt_logo')}/>
                    </div>
                </div>
                <div className="main-container">
                    {this.state.isLoading ? (
                        <div className="main">Loading...</div>
                    ) : (
                        <div className="main">
                            <div className="booking-info">
                                {/* <div className="info-row"> */}
                                    {/* <div>Locker Number:</div> */}
                                    {/* <div>101</div> */}
                                {/* </div> */}
                                <div className="info-row">
                                    <div className="title">Locker Size:</div>
                                    <div className="value">{this.state.selectedLockerSize.name}</div>
                                </div>
                                <div className="info-row">
                                    <div className="title booking-period-title">Booking Period:</div>
                                    <div className="value booking-period-value">
                                        <div>{`${this.state.selectedBookingDurationInHours} hrs`}</div>
                                        <div>{`(${moment(this.state.bookingDurationStartTimestamp).format("DD MMM, HH:mm")} - ${moment(this.state.bookingDurationEndTimestamp).format("DD MMM, HH:mm")})`}</div>
                                    </div>
                                </div>
                                <div className="info-row">
                                    <div className="title">Total Amount:</div>
                                    <div className="value">{`${this.state.totalAmount} Rs.`}</div>
                                </div>
                            </div>
                            <div className="overstay-info">Overstay information</div>
                            <div className="pay-button">Pay</div>
                        </div>
                    )}
                </div>
                <div className="footer-container">
                    <div className="footer"></div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(PaymentDetailsPage);