import {Component} from "react";
import {TOAST_WARN} from "../assets/constants/Constants";
import {getPriceForSelectedLockerSize} from "../lib/BackendUtils";
import ShowToast from "../components/ToastComponent";
import {withTranslation} from "react-i18next";

class BookingPeriodPage extends Component {
    constructor (props) {
        super (props)

        this.state = {
            isLoading: true,
            lockerBankId: localStorage.getItem("lockerBankId"),
            selectedLockerSize: this.props.history.location.state.selectedLockerSize,
            referenceId: this.props.history.location.state.referenceId,
            token: this.props.history.location.state.token,
            lockerPrice: null,
            duration: null,
            durationUnit: null,
            selectedLockerUnit: null,
            enteredDuration: ""
        }
    }

    componentDidMount = () => {this.getPriceForSelectedLockerSize()}

    getPriceForSelectedLockerSize = async () => {
        let headers = {
            'Accept': '*/*',
            'Content-Type': 'application/json',
            'Authorization': `token ${this.state.token}`
        };
        let body = {
            ref_id: this.state.referenceId
        };
        let response = await getPriceForSelectedLockerSize(headers, body, this.state.lockerBankId, this.state.selectedLockerSize);

        this.setState({
            isLoading: false
        }, () => console.log('response: ', response));
    }
    componentWillUnmount = () => {}

    render () {
        return (
            <div className="booking-period-page">
                <div className="header-container">
                    <div className="header">Booking Period</div>
                </div>
                <div className="main-container">
                    {this.state.isLoading ? (
                        <div className="main">
                            <div className="">Loading...</div>
                        </div>
                    ) : (
                        <div className="main">
                            <div className="">How many hours?</div>
                            <div className="">(Per hour Rs. 100/-)</div>
                            <form className="booking-period-form">
                                <input className="form-input" type="number" name="enteredDuration" value={this.state.enteredDuration} placeholder="Enter your hours" onChange={(event) => this.setState({enteredDuration: event.target.value})} />
                            </form>
                            <div className="next-button">Next</div>
                        </div>
                    )}
                </div>
                <div className="footer-container">
                    <div className="footer"></div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(BookingPeriodPage);