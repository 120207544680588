import {Component} from "react";
import {withTranslation} from "react-i18next";
import Select from "react-select";
import {SUCCESS, WARNING, REDIRECT, LUGGAGE, GUEST_AUTH_TOKEN, GUEST_AUTH_TOKEN_VALUE, OTPLESS_AUTHENTICATION_SOURCE, OTPLESS_DUROLT_CID, REGEXP_EMAIL, REGEXP_MOBILE, REGEXP_OTP, TOAST_WARN, OPEN_LOCKER} from "../assets/constants/Constants";
import {APP_WHITE_COLOR} from "../assets/constants/Colors";
import {ROTATING_LOADING_ICON} from "../assets/constants/Icons";
import {sendOTPToUserEmailOrMobile, verifyOTP} from "../lib/BackendUtils";
import ShowToast from "../components/ToastComponent";

class AuthenticationPage extends Component {
    constructor (props) {
        super (props)

        const lockerBankId = localStorage.getItem("lockerBankId");
        const isOTPLoginEnabled = localStorage.getItem("isOTPLoginEnabled");
        const isOTPlessLoginEnabled = localStorage.getItem("isOTPlessLoginEnabled");
        const userAction = localStorage.getItem("userAction");

        if (!lockerBankId) {
            this.props.history.push({
                pathname: "/",
                state: {
                    message: `We couldn't understand which locker bank you were trying to access. Please scan the QR code on the locker bank and try again.`
                }
            });
        }
        else if (!(localStorage.getItem("userAction"))) {
            this.props.history.push({
                pathname: "/",
                state: {
                    message: `We couldn't understand if you were trying to book a new locker or open an already booked locker. Please try again.`
                }
            });
        }
        else {
            localStorage.clear();
            localStorage.setItem("lockerBankId", lockerBankId);
            localStorage.setItem("isOTPLoginEnabled", isOTPLoginEnabled);
            localStorage.setItem("isOTPlessLoginEnabled", isOTPlessLoginEnabled);
            localStorage.setItem("userAction", userAction);
        }

        this.state = {
            isLoading: true,
            awaitingSendOTPResponse: false,
            awaitingVerifyOTPResponse: false,
            isOTPLoginEnabled: isOTPLoginEnabled,
            isOTPlessLoginEnabled: isOTPlessLoginEnabled,
            showLoginFormIfTrueAndVerifyOTPFormIfFalse: true,
            showEmailIdIfTrueOrMobileNumberIfFalse: true,
            showResendOTPButtonWhenCountDownTimerReachesZero: false,
            lockerBankId: lockerBankId,
            userEmailId: "",
            userCountryCode: {value: "+91", label: "IN (+91)"},
            userMobileNumber: "",
            userGroupId: null,
            enteredOTP: "",
            remainingTimeInSecondsToEnableResendOTPButton: 25,
            userAction: userAction
        }
    }

    componentDidMount = () => {
        if (this.state.isOTPlessLoginEnabled) {
            this.constructAndAppendOTPlessScriptElement();
        }
        this.getUserDetailsAfterOTPlessAuthentication();
    }

    constructAndAppendOTPlessScriptElement = () => {
        const otplessScriptElement = document.createElement("script");
        otplessScriptElement.src = OTPLESS_AUTHENTICATION_SOURCE;
        otplessScriptElement.cid = OTPLESS_DUROLT_CID;
        otplessScriptElement.id = "otpless-script";

        const otplessLoginPageElement = document.getElementById("otpless-login-page");
        otplessLoginPageElement.appendChild(otplessScriptElement);
    }

    getUserDetailsAfterOTPlessAuthentication = () => {
        window.otpless = (otplessUserDetails) => {
            alert("Auth page OTPLess alert:"+JSON.stringify(otplessUserDetails));
            console.log("Auth page OTPLess response:"+JSON.stringify(otplessUserDetails));

            //Following parameters (userId, referenceId, authorizationToken) are temporarily set as constants till OTPless verification API call is implemented.
            sessionStorage.setItem("userId", 792);
            sessionStorage.setItem("referenceId", "98ab8655-9885-4f01-a806-75c60dbe7c6d");
            sessionStorage.setItem("authorizationToken", "bae77c6e794ae7675ab78ae1778d32016c121d0b");

            if (this.state.isOTPlessLoginEnabled) {this.removeOTPlessScriptAndWidgetWhenRedirectingToAnotherPage()}

            this.props.history.push({
                pathname: (this.state.userAction === OPEN_LOCKER) ? "/my-lockers" : "/locker-size"
            });
        }
    }

    sendOTPHandler = async (enteredEmailOrMobile) => {
        if (this.validateEnteredEmailOrMobile(enteredEmailOrMobile)) {
            this.setState({
                awaitingSendOTPResponse: true
            });

            let sendOTPResponse = await this.sendOTPToEnteredEmailOrMobile(enteredEmailOrMobile);
            switch (sendOTPResponse.status) {
                case SUCCESS:
                    if (sendOTPResponse.data.user_group_id) {
                        this.setState({
                            awaitingSendOTPResponse: false,
                            userGroupId: sendOTPResponse.data.user_group_id[0],
                            showLoginFormIfTrueAndVerifyOTPFormIfFalse: false,
                            showResendOTPButtonWhenCountDownTimerReachesZero: false
                        }, () => this.countDownTimer());
                    }
                    break;

                case REDIRECT:
                    this.setState({
                        awaitingSendOTPResponse: false
                    });

                    if (this.state.isOTPlessLoginEnabled) {this.removeOTPlessScriptAndWidgetWhenRedirectingToAnotherPage()}

                    this.props.history.push({
                        pathname: "/"
                    });
                    break;

                case WARNING:
                    this.setState({
                        awaitingSendOTPResponse: false
                    });
                    ShowToast(sendOTPResponse.message, TOAST_WARN);
                    break;

                default:
                    this.setState({
                        awaitingSendOTPResponse: false
                    });
                    ShowToast(`warning message`, TOAST_WARN);
            }
        }
        else {
            if (this.state.showEmailIdIfTrueOrMobileNumberIfFalse) {
                ShowToast(`${this.props.t('warning_enter_valid_email')}`, TOAST_WARN);
            }
            else {
                ShowToast(`${this.props.t('warning_enter_valid_mobile')}`, TOAST_WARN);
            }
        }
    }

    verifyOTPHandler = async (enteredEmailOrMobile) => {
        let isEnteredOTPValid = REGEXP_OTP.test(this.state.enteredOTP);

        if (isEnteredOTPValid) {
            this.setState({
                awaitingVerifyOTPResponse: true
            });

            let verifyOTPResponse = await this.verifyEnteredOTP(enteredEmailOrMobile);

            switch (verifyOTPResponse.status) {
                case SUCCESS:
                    if (verifyOTPResponse.data.User_Details) {
                        this.setState({
                            awaitingVerifyOTPResponse: false
                        });

                        sessionStorage.setItem("userId", verifyOTPResponse.data.User_Details.user_id);
                        sessionStorage.setItem("referenceId", verifyOTPResponse.data.User_Details.ref_id);
                        sessionStorage.setItem("authorizationToken", verifyOTPResponse.data.User_Details.token);

                        if (this.state.isOTPlessLoginEnabled) {this.removeOTPlessScriptAndWidgetWhenRedirectingToAnotherPage()}

                        this.props.history.push({
                            pathname: (this.state.userAction === OPEN_LOCKER) ? "/my-lockers" : "/locker-size",
                        });
                    }
                    break;

                case WARNING:
                    this.setState({
                        awaitingVerifyOTPResponse: false
                    });
                    ShowToast(verifyOTPResponse.message, TOAST_WARN);
                    break;

                default:
                    this.setState({
                        awaitingVerifyOTPResponse: false
                    });
                    ShowToast(`warning message`, TOAST_WARN);
            }
        }
        else {
            ShowToast(`${this.props.t('warning_enter_valid_otp')}`, TOAST_WARN);
        }
    }

    validateEnteredEmailOrMobile = (enteredEmailOrMobile) => {
        if (this.state.showEmailIdIfTrueOrMobileNumberIfFalse) {
            return REGEXP_EMAIL.test(enteredEmailOrMobile);
        }
        else {
            return REGEXP_MOBILE.test(enteredEmailOrMobile);
        }
    }

    countDownTimer = () => {
        let remainingTimeInSecondsToEnableResendOTPButton = this.state.remainingTimeInSecondsToEnableResendOTPButton;
        let countDownTimerInterval = setInterval(() => {
            --remainingTimeInSecondsToEnableResendOTPButton;

            if (remainingTimeInSecondsToEnableResendOTPButton === 0 || this.state.showLoginFormIfTrueAndVerifyOTPFormIfFalse) {
                clearInterval(countDownTimerInterval);
                this.setState({
                    showResendOTPButtonWhenCountDownTimerReachesZero: true,
                    remainingTimeInSecondsToEnableResendOTPButton: 25
                });
            }
            else {
                this.setState({
                    remainingTimeInSecondsToEnableResendOTPButton: remainingTimeInSecondsToEnableResendOTPButton
                });
            }
        }, 1000);
    }

    sendOTPToEnteredEmailOrMobile = async (enteredEmailOrMobile) => {
        let additionalHeaders = {[GUEST_AUTH_TOKEN]: GUEST_AUTH_TOKEN_VALUE};
        let body = {
            email_or_mobile: enteredEmailOrMobile,
            locker_bank_id: this.state.lockerBankId,
            use_case: LUGGAGE
        };

        return await sendOTPToUserEmailOrMobile(additionalHeaders, body);
    }

    verifyEnteredOTP = async (enteredEmailOrMobile) => {
        let additionalHeaders = {[GUEST_AUTH_TOKEN]: GUEST_AUTH_TOKEN_VALUE};
        let body = {
            email_or_mobile: enteredEmailOrMobile,
            locker_bank_id: this.state.lockerBankId,
            otp: this.state.enteredOTP,
            user_group_id: [this.state.userGroupId],
            use_case: LUGGAGE
        };

        return await verifyOTP(additionalHeaders, body);
    }

    backButtonHandler = () => {
        this.setState({
            showLoginFormIfTrueAndVerifyOTPFormIfFalse: true
        });
    }

    removeOTPlessScriptAndWidgetWhenRedirectingToAnotherPage = () => {
        document.getElementById("otpless-script")?.remove();
        document.getElementById("otpless-floating-button")?.remove();
        document.getElementById("otpless-loader-style")?.remove();
    }

    componentWillUnmount = () => {}

    render () {
        const {t} = this.props;
        
        return (
            <div className={this.state.showLoginFormIfTrueAndVerifyOTPFormIfFalse ? "authentication-page send-otp-ui" : "authentication-page verify-otp-ui"}>
                <div className="header-container">
                    <div className={this.state.showLoginFormIfTrueAndVerifyOTPFormIfFalse ? "header send-otp-ui" : "header verify-otp-ui"}>
                        {this.state.showLoginFormIfTrueAndVerifyOTPFormIfFalse ? (
                            <img className="durolt-logo send-otp-ui" src={require("../assets/images/durolt_app_logo.png")} alt={t('durolt_logo')}/>
                        ) : (
                            <div className="back-button verify-otp-ui" onClick={() => this.backButtonHandler() }>{`< Back`}</div>
                        )}
                    </div>
                </div>
                <div className="main-container">
                    {this.state.showLoginFormIfTrueAndVerifyOTPFormIfFalse ? (
                        <div className="main">
                            {this.state.isOTPLoginEnabled && (
                                <form className="send-otp-form">
                                    <div className="labels-container">
                                        <label className={this.state.showEmailIdIfTrueOrMobileNumberIfFalse ? 'form-label selected-login-option' : 'form-label'} htmlFor="userEmailId" onClick={() => this.setState({showEmailIdIfTrueOrMobileNumberIfFalse: true})}>{t('email')}</label>
                                        <label className={this.state.showEmailIdIfTrueOrMobileNumberIfFalse ? 'form-label' : 'form-label selected-login-option'} htmlFor="userMobileNumber" onClick={() => this.setState({showEmailIdIfTrueOrMobileNumberIfFalse: false})}>{t('mobile')}</label>
                                    </div>
                                    {this.state.showEmailIdIfTrueOrMobileNumberIfFalse ? (
                                        <input className="form-input" type="text" id="userEmailId" name="userEmailId" value={this.state.userEmailId} placeholder={t('enter_email_id')} onChange={(event) => this.setState({userEmailId: event.target.value})} />
                                    ) : (
                                        <div className="country-code-selector-and-mobile-number-input-container">
                                            <Select
                                                className="react-select country-code-selector"
                                                classNamePrefix="country-code-selector"
                                                isSearchable={true}
                                                isRtl={false}
                                                value={this.state.userCountryCode}
                                                onChange={(event) => this.setState({userCountryCode: event}) }
                                                options={[
                                                    {value: '+91', label: 'IN (+91)'},
                                                    {value: '+1', label: 'US (+1)'},
                                                    {value: 'Code', label: 'Country Name - 2 Digits ISO (Code)'}
                                                ]}
                                            />
                                            <input className="form-input mobile-number-input" type="tel" id="userMobileNumber" name="userMobileNumber" value={this.state.userMobileNumber} placeholder={t('enter_mobile_number')} onChange={(event) => this.setState({userMobileNumber: event.target.value})} />
                                        </div>
                                    )}
                                    <div className="send-otp-button" onClick={() => this.sendOTPHandler(this.state.showEmailIdIfTrueOrMobileNumberIfFalse ? this.state.userEmailId : this.state.userMobileNumber)}>{this.state.awaitingSendOTPResponse ? ROTATING_LOADING_ICON(APP_WHITE_COLOR) : `${t('send_otp')}`}</div>
                                </form>
                            )}
                            {this.state.isOTPlessLoginEnabled && (
                                <div className="otpless-login-page-container">
                                    <div className="otp-and-otpless-login-divider">
                                        <div className="divider-line"></div>
                                        <div className="or-text">OR</div>
                                        <div className="divider-line"></div>
                                    </div>
                                    <div id="otpless-login-page"></div>
                                </div>
                            )}
                        </div>
                    ) : (
                        <div className="main">
                            <form className="verify-otp-form">
                                <label className="form-label">{t('enter_otp_sent_to_user_email_or_mobile', {userEmailOrMobile: this.state.showEmailIdIfTrueOrMobileNumberIfFalse ? this.state.userEmailId : this.state.userMobileNumber})}</label>
                                <input className="form-input" type="number" name="enteredOTP" value={this.state.enteredOTP} placeholder={t('enter_otp')} onChange={(event) => this.setState({enteredOTP: event.target.value})} />
                                {this.state.showResendOTPButtonWhenCountDownTimerReachesZero ? (
                                    <div className="resend-otp-button" onClick={() => this.sendOTPHandler(this.state.showEmailIdIfTrueOrMobileNumberIfFalse ? this.state.userEmailId : this.state.userMobileNumber)}>{t('resend_otp')}</div>
                                ) : (
                                    <div className="countdown-timer">{t('waiting_for_time_in_seconds', {timeInSeconds: this.state.remainingTimeInSecondsToEnableResendOTPButton})}</div>
                                )}
                                <div className="verify-otp-button" onClick={() => this.verifyOTPHandler(this.state.showEmailIdIfTrueOrMobileNumberIfFalse ? this.state.userEmailId : this.state.userMobileNumber)}>{this.state.awaitingVerifyOTPResponse ? ROTATING_LOADING_ICON(APP_WHITE_COLOR) : `${t('verify_otp')}`}</div>
                            </form>
                        </div>
                    )}
                </div>
                <div className="footer-container">
                    <div className="footer"></div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(AuthenticationPage);