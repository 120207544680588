import {Suspense} from "react";
import {withRouter, Switch, Route} from "react-router-dom";
import LandingPage from "../pages/LandingPage";
import HomePage from "../pages/HomePage";
import AuthenticationPage from "../pages/AuthenticationPage";
import MyLockersPage from "../pages/MyLockersPage";
import LockerSizePage from "../pages/LockerSizePage";
import BookingPeriodPage from "../pages/BookingPeriodPage";
import PaymentDetailsPage from "../pages/PaymentDetailsPage";
import PaymentCompletionPage from "../pages/PaymentCompletionPage";
import PaymentAcceptPage from "../pages/PaymentAcceptPage";
import PaymentGatewayiFrame from "../pages/PaymentGatewayiFrame";
import LockerOpenedPage from "../pages/LockerOpenedPage";
import LockerReleasedPage from "../pages/LockerReleasedPage";
import TermsAndConditionsPage from "../pages/TermsAndConditionsPage";
import HelpPage from "../pages/HelpPage";
import ErrorPage from "../pages/ErrorPage";
import { ToastContainer } from "react-toastify";

const RouterComponent = withRouter ( ({location, history}) => {
    return (
        <div className="app">
            <Switch>
                <Route path="/locker-bank/:lockerBankId" render={(props) => <Suspense fallback={<></>} ><LandingPage {...props} /></Suspense> } />
                <Route exact path="/" render={(props) => <Suspense fallback={<></>} ><HomePage {...props} /></Suspense> } />
                <Route path="/login" render={(props) => <Suspense fallback={<></>} ><AuthenticationPage {...props} /></Suspense> } />
                <Route path="/my-lockers" render={(props) => <Suspense fallback={<></>} ><MyLockersPage {...props} /></Suspense> } />
                <Route path="/locker-size" render={(props) => <Suspense fallback={<></>} ><LockerSizePage {...props} /></Suspense> } />
                <Route path="/booking-period" render={(props) => <Suspense fallback={<></>} ><BookingPeriodPage {...props} /></Suspense> } />
                <Route path="/payment-details" render={(props) => <Suspense fallback={<></>} ><PaymentDetailsPage {...props} /></Suspense> } />
                <Route path="/payment-completion" render={(props) => <Suspense fallback={<></>} ><PaymentCompletionPage {...props} /></Suspense> } />
                <Route path="/payment-accept" render={(props) => <Suspense fallback={<></>} ><PaymentAcceptPage {...props} /></Suspense> } />
                <Route path="/payment-gateway" render={(props) => <Suspense fallback={<></>} ><PaymentGatewayiFrame {...props} /></Suspense> } />
                <Route path="/locker-opened" render={(props) => <Suspense fallback={<></>} ><LockerOpenedPage {...props} /></Suspense> } />
                <Route path="/locker-released" render={(props) => <Suspense fallback={<></>} ><LockerReleasedPage {...props} /></Suspense> } />
                <Route path="/terms-and-conditions" render={(props) => <Suspense fallback={<></>} ><TermsAndConditionsPage {...props} /></Suspense> } />
                <Route path="/help" render={(props) => <Suspense fallback={<></>} ><HelpPage {...props} /></Suspense>} />
                <Route path="*" render={(props) => <Suspense fallback={<></>} ><ErrorPage {...props} /></Suspense>} />
            </Switch>
            <ToastContainer/>
        </div>
    );
});

export default RouterComponent;