import {DOMAIN, API, MOBILE, LUGGAGE, LUGGAGE_SETTINGS, SEND_OTP, VERIFY_OTP, AVAILABLE_SIZES, ASSIGN_SIZES, LOCKERS, ASSIGNED, GET, POST, SUCCESS, WARNING, REDIRECT, PRE_PAYMENT_REGISTER, SAVE_TRANSACTION} from "../assets/constants/Constants";

const httpHandler = (url, method, additionalHeaders, body) => {
    const headers = Object.assign({
        'Accept': '*/*',
        'Content-Type': 'application/json'
    }, additionalHeaders);

    let response = fetch (url, {
        method: method,
        headers: headers,
        body: JSON.stringify(body)
    }).then( async (resp) => {
        return {statusCode: resp.status, data: await resp.json()};
    }).then( (response) => {
        switch (response.statusCode) {
            case 200: return Object.assign(response, {status: SUCCESS});
            case 500: return Object.assign(response, {status: WARNING, message: `Something went wrong. Please try again later.`});
            default: return response;
        }
    }).catch((error) => {
        console.log('Error: ', JSON.stringify(error));
    });

    return response;
}

export const getTenantAndAssignmentSettings = async (additionalHeaders, body) => {
    const URL_getTenantAndAssignmentSettings = `${DOMAIN}/${API}/${MOBILE}/${LUGGAGE}/${LUGGAGE_SETTINGS}/`;

    const response = await httpHandler(URL_getTenantAndAssignmentSettings, POST, additionalHeaders, body);

    return response;
}

export const sendOTPToUserEmailOrMobile = async (additionalHeaders, body) => {
    const URL_sendOTPToUserEmailOrMobile = `${DOMAIN}/${API}/${MOBILE}/${LUGGAGE}/${SEND_OTP}/`;

    const response = await httpHandler(URL_sendOTPToUserEmailOrMobile, POST, additionalHeaders, body);
    switch (response.statusCode) {
        case 422: return Object.assign(response, {status: REDIRECT});
    }

    return response;
}

export const verifyOTP = async (additionalHeaders, body) => {
    const URL_verifyOTP = `${DOMAIN}/${API}/${MOBILE}/${LUGGAGE}/${VERIFY_OTP}/`;

    const response = await httpHandler(URL_verifyOTP, POST, additionalHeaders, body);
    switch (response.statusCode) {
        case 401: return Object.assign(response, {status: WARNING, message: `Entered OTP is invalid. Please enter correct OTP.`});
        case 410: return Object.assign(response, {status: WARNING, message: `Entered OTP is expired. Please click on "Resend OTP" to get a new OTP.`});
    }

    return response;
}

export const getLockerSizeAvailability = async (additionalHeaders, body, lockerBankId) => {
    const URL_getLockerSizeAvailability = `${DOMAIN}/${API}/${MOBILE}/${LUGGAGE}/${AVAILABLE_SIZES}/${lockerBankId}/`;

    const response = await httpHandler(URL_getLockerSizeAvailability, POST, additionalHeaders, body);

    return response;
}

export const getPriceForSelectedLockerSize = async (additionalHeaders, body, lockerBankId, selectedLockerSize) => {
    const URL_getPriceForSelectedLockerSize = `${DOMAIN}/${API}/${MOBILE}/${LUGGAGE}/${ASSIGN_SIZES}/${lockerBankId}/${selectedLockerSize}/`;

    const response = await httpHandler(URL_getPriceForSelectedLockerSize, POST, additionalHeaders, body);

    return response;
}

export const getAssignedLockers = async (additionalHeaders) => {
    const URL_getAssignedLockers = `${DOMAIN}/${API}/${MOBILE}/${LOCKERS}/${ASSIGNED}/v2`;

    const response = await httpHandler(URL_getAssignedLockers, GET, additionalHeaders);

    return response;
}

export const prePaymentRegistration = async (additionalHeaders, body) => {
    const URL_prePaymentRegistration = `${DOMAIN}/${API}/${MOBILE}/${PRE_PAYMENT_REGISTER}/`;

    const response = await httpHandler(URL_prePaymentRegistration, POST, additionalHeaders, body);

    return response;
}

export const postPaymentSaveTransaction = async (additionalHeaders, body) => {
    const URL_postPaymentSaveTransaction = `${DOMAIN}/${API}/${MOBILE}/${SAVE_TRANSACTION}/`;

    const response = await httpHandler(URL_postPaymentSaveTransaction, POST, additionalHeaders, body);

    return response;
}

export const openLocker = async (additionalHeaders, body) => {
    const URL_openLocker = `${DOMAIN}`;

    const response = await httpHandler(URL_openLocker, POST, additionalHeaders, body);

    return response;
}

export const releaseLocker = async (additionalHeaders, body) => {
    const URL_releaseLocker = `${DOMAIN}`;

    const response = await httpHandler(URL_releaseLocker, POST, additionalHeaders, body);

    return response;
}